<template>
    <div class="desc-container p-3 text-left" :style="{maxHeight: height}">
        <div v-for="ability in data" :key="ability.name">
            <small class="fw-bold mb-1 d-block" :style="{color: colors.title}">{{ability.name}} ({{gkey.toUpperCase()}})</small>
            <hr class="my-2">
            <div class="mb-2" v-if="ability.cost">
                <small class="fw-bold mb-1 d-block" :style="{color: colors.attribute}">Cost</small>
                <p v-for="(modifier, modind) in ability.cost.modifiers"
                    :key="ability.name + '-cost-' + modind"
                    class="my-0"
                >
                    {{parseModifier(modifier, ability.resource)}}
                </p>
            </div>
            <div class="mb-2" v-if="ability.cooldown">
                <small class="fw-bold mb-1 d-block" :style="{color: colors.attribute}">Cooldown</small>
                <p v-for="(modifier, modind) in ability.cooldown.modifiers"
                    :key="ability.name + '-cooldown-' + modind"
                    class="my-0"
                >
                    {{parseModifier(modifier)}} s
                </p>
            </div>
            <div v-for="(effect, ind) in ability.effects" :key="ability.name + ind">
                <p>{{effect.description}}</p>
                <div class="ms-2 mb-2" v-for="(leveling, levelind) in effect.leveling" :key="ability.name + ind + '-leveling-' + levelind">
                    <small class="fw-bold mb-1 d-block" :style="{color: colors.attribute}">{{leveling.attribute}}</small>
                    <p v-for="(modifier, modind) in leveling.modifiers"
                        :key="ability.name + ind + '-leveling-' + levelind + '-modifier-' + modind"
                        class="my-0"
                    >
                        {{parseModifier(modifier)}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.slight-dark-bg {
    background-color: rgba(0, 0, 0, 0.5);
}

.desc-container {
    overflow-y: scroll;
    overflow-x: hidden;
}

p {
    font-size: 12px;
}

</style>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'

@Options({components: {Image}})
export default class ChampionAbility extends Vue {

    colors: Record<string, string> = {
        "title": "rgb(0, 223, 126)",
        "attribute": "rgb(0, 223, 201)"
    }

    abilities: Record<string, any> = {}
    @Prop({default: ""}) readonly defHeight!: string
    @Prop({default: ""}) readonly mdHeight!: string
    @Prop({default: ""}) readonly gkey!: string
    @Prop({default: null}) readonly data!: any

    get height(): string {
        if (this.$store.state.winWidth >= 768)
            return this.mdHeight
        return this.defHeight
    }

    parseModifier(modifier: Record<string, Array<number | string>>, defaultUnit = ""): string {
        const o = []
        const allUnitSame = modifier.units.every((val, i, arr) => val === arr[0])
        for (const i of this.$itertool.range(0, modifier.values.length)) {
            const value = isNaN(modifier.values[i] as number) || Number.isInteger(modifier.values[i]) ?
                modifier.values[i] : this.$math.round(modifier.values[i] as number, 100)
            if (allUnitSame)
                o.push(value.toString())
            else
                o.push(value.toString() + modifier.units[i].toString())
        }
        let str = o.join(" / ")
        if (defaultUnit)
            defaultUnit = defaultUnit[0].toUpperCase() + defaultUnit.substring(1).toLowerCase()
        if (allUnitSame)
            str += " " + (modifier.units[0] || defaultUnit)
        return str
    }

}
</script>
