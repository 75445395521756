<template>
    <Image
        :src="$cdn.url('lol', ['champions', gid + '_' + name.toLowerCase()], 'png')"
        :size="Isize"
        :class="active ? 'active-ability-outline': ''"
    />
</template>

<style lang="scss" scoped>

.active-ability-outline {
    outline: 2px solid rgb(0, 251, 255);
    outline-offset: 2px;
}

</style>

<script lang="ts">
import { Watch, Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'

@Options({components: {Image}})
export default class ChampionAbility extends Vue {

    abilities: Record<string, any> = {}
    Isize: number | string = ""

    @Prop(String) readonly name!: string
    @Prop(Number) readonly gid!: number
    @Prop({default: false}) readonly active!: boolean
    @Prop({default: "100%"}) readonly size!: number
    @Prop({default: []}) readonly rSize!: Array<number>

    get winWidth(): number {
        return this.$store.state.winWidth
    }

    created (): void {
        this.getSize(this.winWidth)
    }

    @Watch('winWidth')
    getSize(after: number | string): void {
        if (this.rSize.length == 0)
            this.Isize = this.$css.asDimension(this.size)
        else if (after < 576)
            this.Isize = this.$css.asDimension(this.rSize[0])
        else if (after < 992)
            this.Isize = this.$css.asDimension(this.rSize[1])
        else
            this.Isize = this.$css.asDimension(this.rSize[2])
    }

}
</script>
