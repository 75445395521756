
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Item from '@/components/lol/Item.vue'
import Rune from '@/components/lol/Rune.vue'
import Spell from '@/components/lol/Spell.vue'
import SkillLevels from '@/components/lol/SkillLevels.vue'
import Line from '@/components/charts/Line.vue'


interface RuneData {
    win_rate: number
    pick_rate: number
    premise: Array<number>
}

@Options({components: {Item, Rune, Spell, SkillLevels, Line}})
export default class ChampionOverview extends Vue {

    @Prop(Object) readonly data!: any

    parseRateHistory (history: Array<Record<string, number>>, label: string, dateFormat: string): any {
        const o: Record<string, any> = {
            labels: [],
            datasets: [
                {
                    label: label,
                    backgroundColor: "#00ffea88",
                    data: []
                }
            ]
        }
        for (const item of history) {
            o.labels.push(this.$moment(item.ts * 1000).format(dateFormat))
            o.datasets[0].data.push(this.$math.round(item.rate * 100, 100))
        }
        return o
    }

    parseRunes (runes: Record<string, Array<RuneData>>, limit = 2): Array<RuneData> {
        const o: Array<RuneData> = []
        for (const runeArr of Object.values(runes)) {
            for (const rune of runeArr) {
                o.push(rune)
            }
        }
        o.sort((a, b) => b.pick_rate - a.pick_rate)
        return o.slice(0, limit)
    }

}
