
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'

@Options({components: {Image}})
export default class ChampionAbility extends Vue {

    colors: Record<string, string> = {
        "title": "rgb(0, 223, 126)",
        "attribute": "rgb(0, 223, 201)"
    }

    abilities: Record<string, any> = {}
    @Prop({default: ""}) readonly defHeight!: string
    @Prop({default: ""}) readonly mdHeight!: string
    @Prop({default: ""}) readonly gkey!: string
    @Prop({default: null}) readonly data!: any

    get height(): string {
        if (this.$store.state.winWidth >= 768)
            return this.mdHeight
        return this.defHeight
    }

    parseModifier(modifier: Record<string, Array<number | string>>, defaultUnit = ""): string {
        const o = []
        const allUnitSame = modifier.units.every((val, i, arr) => val === arr[0])
        for (const i of this.$itertool.range(0, modifier.values.length)) {
            const value = isNaN(modifier.values[i] as number) || Number.isInteger(modifier.values[i]) ?
                modifier.values[i] : this.$math.round(modifier.values[i] as number, 100)
            if (allUnitSame)
                o.push(value.toString())
            else
                o.push(value.toString() + modifier.units[i].toString())
        }
        let str = o.join(" / ")
        if (defaultUnit)
            defaultUnit = defaultUnit[0].toUpperCase() + defaultUnit.substring(1).toLowerCase()
        if (allUnitSame)
            str += " " + (modifier.units[0] || defaultUnit)
        return str
    }

}
