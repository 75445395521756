<template>
    <div class="row px-3">
        <div class="col-12 col-md-6 px-2 ps-md-4 pe-md-4 d-block d-md-flex">
            <div class="mt-4 w-100">
                <h4 class="text-left ms-2">Ability Level Ups</h4>
                <div class="my-4">
                    <div class="d-flex">
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.skills"
                        :key="'skill-row-' + ind"
                        class="d-flex ms-0"
                    >
                        <SkillLevels
                            :slots="items.premise"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 px-2 ps-md-4 pe-md-4 d-block d-md-flex">
            <div class="mt-4 w-100">
                <h4 class="text-left ms-2">Summoner Spells</h4>
                <div class="my-4">
                    <div class="d-flex">
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.spells"
                        :key="'spell-row-' + ind"
                        class="d-flex ms-1"
                    >
                        <Spell
                            v-for="item in items.premise"
                            :gid="item"
                            :key="'spell-row-' + ind + '-item-' + item"
                            :r-size="[35, 37, 42]"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

@import '@/scss/transitions.scss';

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SkillLevels from '@/components/lol/SkillLevels.vue'
import Spell from '@/components/lol/Spell.vue'

@Options({components: {SkillLevels, Spell}})
export default class ChampionRunes extends Vue {

    @Prop(Object) readonly data!: any

}
</script>
