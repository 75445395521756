
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'
import ChampionAbilityIcon from '@/components/lol/ChampionAbilityIcon.vue'
import ChampionAbilityDescription from '@/components/lol/ChampionAbilityDescription.vue'
import ChampionOverview from '@/components/lol/ChampionOverview.vue'
import ChampionBuilds from '@/components/lol/ChampionBuilds.vue'
import ChampionRunes from '@/components/lol/ChampionRunes.vue'
import ChampionSkills from '@/components/lol/ChampionSkills.vue'
import ChampionTrends from '@/components/lol/ChampionTrends.vue'

@Options({
    components: {
        Champion,
        ChampionAbilityIcon,
        ChampionAbilityDescription,
        ChampionOverview,
        ChampionBuilds,
        ChampionRunes,
        ChampionSkills,
        ChampionTrends
    }
})
export default class LolChampions extends Vue {

    elo = ""
    role = ""
    loading = true
    error = false
    errorDetail = ""
    champKey = ""
    champName = ""
    champId = -1
    data: any = {}
    abilities: any = {}
    
    selectedAbilityKey = ""
    selectedPanel = "overview"

    @Watch('role')
    roleChanged(after: string, before: string): void {
        if (before)
            this.$router.push(`/lol/champions/${this.elo}/${this.champKey}/${after}`)
    }

    @Watch('elo')
    eloChanged(after: string, before: string): void {
        if (before)
            this.$router.push(`/lol/champions/${after}/${this.champKey}/${this.role}`)
    }

    async created (): Promise<void> {
        this.elo = this.$route.params.elo.toString()
        this.champKey = this.$route.params.key.toString()
        this.role = this.$route.params.role.toString()
        if (this.$asset.lol.champkeys[this.champKey] == undefined) {
            this.loading = false
            this.error = true
            this.errorDetail = "Champion not found"
        }
        this.champId = this.$asset.lol.champkeys[this.champKey].id
        this.champName = this.$asset.lol.champkeys[this.champId].name
        try {
            const stat = this.$cdn.get('lol', ['analytics', 'champion-stats', this.elo, this.champId, this.role], 'json')
            const build = this.$cdn.get('lol', ['analytics', 'champion-builds', this.elo, this.champId, this.role], 'json')
            this.data.stat = await stat
            this.data.style = await build
        } catch (err: any) {
            this.loading = false
            this.error = true
            this.errorDetail = err.response.data.detail
            return
        }
        try {
            this.abilities = await this.$cdn.get('lol', ['champions', this.champId + "_abilities"], "json")
        } catch (err) {
            this.abilities = {e: [], p: [], q: [], r: [], w: []}
        }
        this.$meta.use({
            title: `${this.champName} ${this.capfirst(this.role)} ${this.capfirst(this.elo)} Elo Builds, Runes, Stats | StatHub.gg LoL`,
            og: {
                image: this.$cdn.url('lol', ['champions', this.champId], 'png'),
                description: `${this.champName} ${this.capfirst(this.role)} ${this.capfirst(this.elo)} Elo |
                    Most up to date builds, runes, skill orders and statistics |
                    Win Rate: ${this.$math.round(this.data.stat.win_rate *100, 100)}% |
                    Pick Rate: ${this.$math.round(this.data.stat.pick_rate *100, 100)}% |
                    Ban Rate: ${this.$math.round(this.data.stat.ban_rate *100, 100)}% |
                    `
            }
        })
        this.loading = false
    }

    capfirst(str: string): string {
        return str.charAt(0).toUpperCase() + str.substring(1)
    }

    availableRoles (roles: Record<string, number>): Array<any> {
        const record = this.$asset.asRecord(this.$asset.lol.longRoles)
        const o = []
        for (const [k, v] of Object.entries(roles)) {
            o.push({
                title: record[k] + " " + this.$math.round(v * 100, 100) + "%",
                value: k
            })
        }
        return o
    }

}
