<template>
    <div class="row px-3">
        <div class="col-12 col-md-8 px-2 ps-md-4 pe-md-5 d-block d-md-flex">
            <div class="mt-1 mt-md-4 text-left d-flex d-md-block me-0 me-md-5 overflow-auto flex-nowrap">
                <h5 class="ms-3 d-none d-md-block">Keystone</h5>
                    <div
                        v-for="tree in runeTrees"
                        :key="'rune-tab-' + tree"
                        :class="['tab-button text-nowrap', (selectedRuneTree == tree) ? 'active': '']"
                        @click="selectedRuneTree = tree"
                    >
                        <Rune
                            :gid="tree"
                            :r-size="[50, 57, 65]"
                            class="m-2 d-inline-block"
                        />
                        <span class="small text-white d-none d-lg-inline-block">
                            {{$asset.lol.runes[tree].name}}
                        </span>
                    </div>
            </div>
            <div class="mt-3 mt-md-4 flex-fill">
                <transition name="rune-tab-fade" mode="out-in">
                    <div class="mb-4" :key="selectedRuneTree">
                        <div class="d-flex ms-2 align-items-center">
                            <h5>Core Runes</h5>
                            <div class="extra-small text-muted ms-3 me-auto"></div>
                            <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                            <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                        </div>
                        <div
                            v-for="(items, treeind) in runes[selectedRuneTree]"
                            :key="'rune-row-' + selectedRuneTree + treeind"
                            class="d-flex ms-1"
                        >
                            <Rune
                                v-for="(item, ind) in items.premise"
                                :gid="item"
                                :key="'rune-row-' + selectedRuneTree + treeind + '-item-' + item"
                                :r-size="ind == 0 ? [50, 57, 65]: [26, 32, 38]"
                                :class="ind == 4 ? 'ms-2 ms-lg-3 my-auto': 'my-auto'"
                            />
                            <div class="ms-auto d-flex">
                                <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                                <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="col-12 col-md-4 px-2 ps-md-4 pe-md-5">
            <div class="mt-4">
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <h5>Stat Runes</h5>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.stat_runes"
                        :key="'statrune-row-' + ind"
                        class="d-flex ms-1"
                    >
                        <Rune
                            v-for="item in items.premise"
                            :gid="item"
                            :key="'statrune-row-' + ind + '-item-' + item"
                            :r-size="[35, 37, 42]"
                            :class="['my-auto']"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

@import '@/scss/transitions.scss';

@include fade('rune-tab-fade', .06s);
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Rune from '@/components/lol/Rune.vue'


interface RuneData {
    win_rate: number
    pick_rate: number
    premise: Array<number>
}

@Options({components: {Rune}})
export default class ChampionRunes extends Vue {

    @Prop(Object) readonly data!: any

    selectedRuneTree = 0
    runeTrees: Array<number> = []
    runes: Record<string, Array<RuneData>> = {}

    created (): void {
        const runes: Record<string, Array<RuneData>> = this.data.style.runes
        const o: Array<[number, number]> = []
        const visited: Array<number> = []
        for (const runeArr of Object.values(runes)) {
            for (const rune of runeArr) {
                if (!Object.keys(this.runes).includes(rune.premise[0].toString()))
                    this.runes[rune.premise[0]] = []
                this.runes[rune.premise[0]].push(rune)
                if (visited.includes(rune.premise[0]))
                    continue
                o.push([rune.premise[0], rune.pick_rate])
                visited.push(rune.premise[0])
            }
        }
        o.sort((a, b) => b[1] - a[1])
        this.selectedRuneTree = o[0][0]
        this.runeTrees = o.map((a) => a[0])
    }

}
</script>
