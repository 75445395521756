<template>
    <div class="rune">
        <VTooltip>
            <Image :src="$cdn.url('lol', ['runes', gid], 'png')" :rounded="true" :size="Isize"/>

            <template #popper>
                <div class="description" v-if="!isTree">
                    <span class="fw-bold" :style="{fontSize: '14px', color: colors.title}">{{$asset.lol.runes[gid].name}}</span>
                    <hr class="my-2">
                    <span v-html="$asset.lol.runes[gid].longDesc"></span>
                </div>
            </template>
        </VTooltip>
    </div>
</template>

<style lang="scss" scoped>
.rune {
    position: relative;
}

.mythic-outline {
    outline: 1px solid rgb(242, 255, 0);
    outline-offset: -1px;
    border-radius: 50%;
}

.description {
    white-space: pre-wrap;
    max-width: min(100vw, 500px);
    font-size: 12px;
}
</style>

<script lang="ts">
import { Watch, Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'

@Options({components: {Image}})
export default class Rune extends Vue {

    Isize: number | string = ""
    uuid!: string

    @Prop() readonly gid!: number
    @Prop({default: "100%"}) readonly size!: number
    @Prop({default: []}) readonly rSize!: Array<number>
    @Prop({default: false}) readonly isTree!: boolean

    colors: Record<string, string> = {
        "title": "rgb(0, 223, 126)",
        "attribute": "rgb(0, 223, 201)"
    }

    get winWidth(): number {
        return this.$store.state.winWidth
    }

    created (): void {
        this.uuid = this.$uuid.uuid1()
        this.getSize(this.winWidth)
    }

    @Watch('winWidth')
    getSize(after: number | string): void {
        if (this.rSize.length == 0)
            this.Isize = this.$css.asDimension(this.size)
        else if (after < 576)
            this.Isize = this.$css.asDimension(this.rSize[0])
        else if (after < 992)
            this.Isize = this.$css.asDimension(this.rSize[1])
        else
            this.Isize = this.$css.asDimension(this.rSize[2])
    }

}
</script>
