
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Rune from '@/components/lol/Rune.vue'


interface RuneData {
    win_rate: number
    pick_rate: number
    premise: Array<number>
}

@Options({components: {Rune}})
export default class ChampionRunes extends Vue {

    @Prop(Object) readonly data!: any

    selectedRuneTree = 0
    runeTrees: Array<number> = []
    runes: Record<string, Array<RuneData>> = {}

    created (): void {
        const runes: Record<string, Array<RuneData>> = this.data.style.runes
        const o: Array<[number, number]> = []
        const visited: Array<number> = []
        for (const runeArr of Object.values(runes)) {
            for (const rune of runeArr) {
                if (!Object.keys(this.runes).includes(rune.premise[0].toString()))
                    this.runes[rune.premise[0]] = []
                this.runes[rune.premise[0]].push(rune)
                if (visited.includes(rune.premise[0]))
                    continue
                o.push([rune.premise[0], rune.pick_rate])
                visited.push(rune.premise[0])
            }
        }
        o.sort((a, b) => b[1] - a[1])
        this.selectedRuneTree = o[0][0]
        this.runeTrees = o.map((a) => a[0])
    }

}
