<template>
    <div class="text-left pb-3 row">
        <div class="mt-4 px-2 ps-md-4 pe-md-4">
            <h4 class="ms-3">Win Rate History</h4>
            <div class="row mx-0">
                <Line
                    class="col-12 col-md-6"
                    :data="parseRateHistory(data.stat.win_rate_history_24h, 'Win Rate 24 Hours', 'hA')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
                <Line
                    class="col-12 col-md-6"
                    :data="parseRateHistory(data.stat.win_rate_history_30d, 'Win Rate 30 Days', 'MM/DD')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
            </div>
        </div>
        <div class="mt-4 px-2 ps-md-4 pe-md-4">
            <h4 class="ms-3">Pick Rate History</h4>
            <div class="row mx-0">
                <Line
                    class="col-12 col-md-6"
                    :data="parseRateHistory(data.stat.pick_rate_history_24h, 'Pick Rate 24 Hours', 'hA')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
                <Line
                    class="col-12 col-md-6"
                    :data="parseRateHistory(data.stat.pick_rate_history_30d, 'Pick Rate 30 Days', 'MM/DD')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
            </div>
        </div>
        <div class="mt-4 px-2 ps-md-4 pe-md-4">
            <h4 class="ms-3">Ban Rate History</h4>
            <div class="row mx-0">
                <Line
                    class="col-12 col-md-6"
                    :data="parseRateHistory(data.stat.ban_rate_history_24h, 'Ban Rate 24 Hours', 'hA')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
                <Line
                    class="col-12 col-md-6"
                    :data="parseRateHistory(data.stat.ban_rate_history_30d, 'Ban Rate 30 Days', 'MM/DD')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({})
export default class ChampionTrends extends Vue {

    @Prop(Object) readonly data!: any

    parseRateHistory (history: Array<Record<string, number>>, label: string, dateFormat: string): any {
        const o: Record<string, any> = {
            labels: [],
            datasets: [
                {
                    label: label,
                    backgroundColor: "#00ffea88",
                    data: []
                }
            ]
        }
        for (const item of history) {
            o.labels.push(this.$moment(item.ts * 1000).format(dateFormat))
            o.datasets[0].data.push(this.$math.round(item.rate * 100, 100))
        }
        return o
    }

}
</script>
