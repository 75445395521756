<template>
    <div class="row px-0 mx-0">
        <div class="col-12 col-md-6 px-2 ps-md-4 pe-md-5">
            <div class="mt-4">
                <h4 class="text-left ms-2">Start Items</h4>
                <div class="my-4">
                    <div class="d-flex">
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.starting_items"
                        :key="'startitem-row-' + ind"
                        class="d-flex"
                    >
                        <Item
                            v-for="item in $itertool.counter(items.premise)"
                            :key="'startitem-row-' + ind + '-item-' + item.object"
                            :gid="item.object"
                            :count="item.amount"
                            :r-size="[35, 37, 42]"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <h4 class="text-left ms-2">Boots</h4>
                <div class="mb-4 mt-3 d-flex flex-wrap">
                    <div class="ms-2">
                        <div class="extra-small text-muted text-center pt-4" :style="$store.state.winWidth < 992 ? 'height: 60px': 'height: 66px'">#</div>
                        <div class="extra-small text-muted pe-2" style="height: 20px">Wr</div>
                        <div class="extra-small text-muted pe-2" style="height: 20px">Pr</div>
                    </div>
                    <div
                        v-for="item in data.style.boots"
                        :key="'boot-' + item"
                        class="my-2 px-0 px-lg-2"
                    >
                        <Item
                            :gid="item.premise"
                            :r-size="[35, 37, 42]"
                            class="d-block d-flex justify-content-center my-2"
                        />
                        <div class="">
                            <div class="my-auto extra-small" style="width: 60px">{{$math.round(item.win_rate * 100, 100)}}%</div>
                            <div class="my-auto extra-small" style="width: 60px">{{$math.round(item.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$store.state.winWidth >= 768" id="champions-situational-items"></div>
        </div>
        <div class="col-12 col-md-6 px-2 ps-md-4 pe-md-5">
            <div class="mt-4">
                <h4 class="text-left ms-2">Core Items</h4>
                <div class="my-4">
                    <div class="d-flex">
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.core_items"
                        :key="'coreitem-row-' + ind"
                        class="d-flex"
                    >
                        <Item
                            v-for="item in items.premise"
                            :gid="item"
                            :key="'coreitem-row-' + ind + '-item-' + item"
                            :r-size="[35, 37, 42]"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$store.state.winWidth < 768" id="champions-situational-items"></div>
            <teleport to='#champions-situational-items' v-if="isMounted">
                <div class="mt-4">
                    <h4 class="text-left ms-2">Situational Items</h4>
                    <div class="mb-4 mt-3 d-flex flex-wrap">
                        <div class="ms-2">
                            <div class="extra-small text-muted text-center pt-4" :style="$store.state.winWidth < 992 ? 'height: 60px': 'height: 66px'">#</div>
                            <div class="extra-small text-muted pe-2" style="height: 20px">Wr</div>
                            <div class="extra-small text-muted pe-2" style="height: 20px">Pr</div>
                        </div>
                        <div
                            v-for="item in data.style.situational_items"
                            :key="'situationalitem-' + item"
                            class="my-2 px-0 px-lg-2"
                        >
                            <Item
                                :gid="item.premise"
                                :r-size="[35, 37, 42]"
                                class="d-block d-flex justify-content-center my-2"
                            />
                            <div class="">
                                <div class="my-auto extra-small" style="width: 60px">{{$math.round(item.win_rate * 100, 100)}}%</div>
                                <div class="my-auto extra-small" style="width: 60px">{{$math.round(item.pick_rate * 100, 100)}}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </teleport>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Item from '@/components/lol/Item.vue'

@Options({components: {Item}})
export default class ChampionBuilds extends Vue {

    @Prop(Object) readonly data!: any

    situationalItemsTeleport = false
    isMounted = false

    @Watch('$store.state.winWidth')
    winWidthChanged (after: number, before: number): void {
        if (before > 768 && after < 768 || before < 768 && after > 768)
            this.isMounted = false
        setTimeout(() => this.isMounted = true, 100)
    }

    mounted(): void {
        this.isMounted = true
    }

}
</script>
