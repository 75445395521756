
import { Watch, Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Image from '../Image.vue'

@Options({components: {Image}})
export default class Spell extends Vue {

    Isize: number | string = ""
    uuid!: string
    blankId = 54

    @Prop() readonly gid!: number
    @Prop({default: "100%"}) readonly size!: number
    @Prop({default: []}) readonly rSize!: Array<number>

    colors: Record<string, string> = {
        "title": "rgb(0, 223, 126)",
        "attribute": "rgb(0, 223, 201)"
    }

    get winWidth(): number {
        return this.$store.state.winWidth
    }

    created (): void {
        this.uuid = this.$uuid.uuid1()
        this.getSize(this.winWidth)
    }

    @Watch('winWidth')
    getSize(after: number | string): void {
        if (this.rSize.length == 0)
            this.Isize = this.$css.asDimension(this.size)
        else if (after < 576)
            this.Isize = this.$css.asDimension(this.rSize[0])
        else if (after < 992)
            this.Isize = this.$css.asDimension(this.rSize[1])
        else
            this.Isize = this.$css.asDimension(this.rSize[2])
    }

}
