
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Item from '@/components/lol/Item.vue'

@Options({components: {Item}})
export default class ChampionBuilds extends Vue {

    @Prop(Object) readonly data!: any

    situationalItemsTeleport = false
    isMounted = false

    @Watch('$store.state.winWidth')
    winWidthChanged (after: number, before: number): void {
        if (before > 768 && after < 768 || before < 768 && after > 768)
            this.isMounted = false
        setTimeout(() => this.isMounted = true, 100)
    }

    mounted(): void {
        this.isMounted = true
    }

}
