<template>
    <div class="row px-3">
        <div class="col-12 col-md-5 px-2 ps-md-4 pe-md-4">
            <div class="mt-4">
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <div class="h5">Starting Items</div>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.starting_items.slice(0, 2)"
                        :key="'startitem-row-' + ind"
                        class="d-flex"
                    >
                        <Item
                            v-for="item in $itertool.counter(items.premise)"
                            :key="'startitem-row-' + ind + '-item-' + item.object"
                            :gid="item.object"
                            :count="item.amount"
                            :r-size="[35, 37, 42]"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <div class="h5">Core Items</div>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.core_items.slice(0, 3)"
                        :key="'coreitem-row-' + ind"
                        class="d-flex"
                    >
                        <Item
                            v-for="item in items.premise"
                            :gid="item"
                            :key="'coreitem-row-' + ind + '-item-' + item"
                            :r-size="[35, 37, 42]"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
                <div class="text-left mt-3 ms-2">
                    <div class="h5">Boots</div>
                </div>
                <div class="mb-4 d-flex flex-wrap">
                    <div class="ms-2">
                        <div class="extra-small text-muted text-center pt-4" :style="$store.state.winWidth < 992 ? 'height: 60px': 'height: 66px'">#</div>
                        <div class="extra-small text-muted pe-2" style="height: 20px">Wr</div>
                        <div class="extra-small text-muted pe-2" style="height: 20px">Pr</div>
                    </div>
                    <div
                        v-for="item in data.style.boots.slice(0, 5)"
                        :key="'boot-' + item"
                        class="my-2 px-0 px-lg-2"
                    >
                        <Item
                            :gid="item.premise"
                            :r-size="[35, 37, 42]"
                            class="d-block d-flex justify-content-center my-2"
                        />
                        <div class="">
                            <div class="my-auto extra-small" style="width: 60px">{{$math.round(item.win_rate * 100, 100)}}%</div>
                            <div class="my-auto extra-small" style="width: 60px">{{$math.round(item.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
                <div class="text-left mt-3 ms-2">
                    <div class="h5">Situational Items</div>
                </div>
                <div class="mb-4 d-flex flex-wrap">
                    <div class="ms-2">
                        <div class="extra-small text-muted text-center pt-4" :style="$store.state.winWidth < 992 ? 'height: 60px': 'height: 66px'">#</div>
                        <div class="extra-small text-muted pe-2" style="height: 20px">Wr</div>
                        <div class="extra-small text-muted pe-2" style="height: 20px">Pr</div>
                    </div>
                    <div
                        v-for="item in data.style.situational_items.slice(0, 5)"
                        :key="'situationalitem-' + item"
                        class="my-2 px-0 px-lg-2"
                    >
                        <Item
                            :gid="item.premise"
                            :r-size="[35, 37, 42]"
                            class="d-block d-flex justify-content-center my-2"
                        />
                        <div class="">
                            <div class="my-auto extra-small" style="width: 55px">{{$math.round(item.win_rate * 100, 100)}}%</div>
                            <div class="my-auto extra-small" style="width: 55px">{{$math.round(item.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-12 col-md-5 px-2 pe-md-4">
            <div class="mt-1 mt-md-4">
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <div class="h5">Runes</div>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in parseRunes(data.style.runes, 2)"
                        :key="'rune-row-' + ind"
                        class="d-flex ms-0"
                    >
                        <Rune
                            v-for="(item, ind) in items.premise"
                            :gid="item"
                            :key="'rune-row-' + ind + '-item-' + item"
                            :r-size="ind == 0 ? [50, 57, 65]: [25, 30, 35]"
                            :class="ind == 4 ? 'ms-2 ms-lg-3 my-auto': 'my-auto'"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <div class="h5">Stat Runes</div>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.stat_runes.slice(0, 3)"
                        :key="'statrune-row-' + ind"
                        class="d-flex ms-1"
                    >
                        <Rune
                            v-for="item in items.premise"
                            :gid="item"
                            :key="'statrune-row-' + ind + '-item-' + item"
                            :r-size="[35, 37, 42]"
                            :class="['my-auto']"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="mt-4">
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <div class="h5">Summoner Spells</div>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.spells.slice(0, 2)"
                        :key="'spell-row-' + ind"
                        class="d-flex ms-1"
                    >
                        <Spell
                            v-for="item in items.premise"
                            :gid="item"
                            :key="'spell-row-' + ind + '-item-' + item"
                            :r-size="[35, 37, 42]"
                            class="m-2"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="mt-4">
                <div class="my-4">
                    <div class="d-flex ms-2 align-items-center">
                        <div class="h5">Ability Level Ups</div>
                        <div class="extra-small text-muted ms-3 me-auto"></div>
                        <div class="extra-small text-muted" style="width: 65px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 65px">Pick Rate</div>
                    </div>
                    <div
                        v-for="(items, ind) in data.style.skills.slice(0, 2)"
                        :key="'skill-row-' + ind"
                        class="d-flex ms-0"
                    >
                        <SkillLevels
                            :slots="items.premise"
                            class="my-2 mx-1"
                        />
                        <div class="ms-auto d-flex">
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 65px">{{$math.round(items.pick_rate * 100, 100)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2 text-left mb-4">
            <div class="mt-4 mx-1">
                <h5>Win Rates</h5>
                <Line
                    :data="parseRateHistory(data.stat.win_rate_history_30d.slice(-5), 'Win Rate 5 Days', 'MM/DD')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
            </div>
            <div class="mt-4 mx-1">
                <h5>Pick Rates</h5>
                <Line
                    :data="parseRateHistory(data.stat.pick_rate_history_30d.slice(-5), 'Pick Rate 5 Days', 'MM/DD')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
            </div>
            <div class="mt-4 mx-1">
                <h5>Ban Rates</h5>
                <Line
                    :data="parseRateHistory(data.stat.ban_rate_history_30d.slice(-5), 'Ban Rate 5 Days', 'MM/DD')"
                    :options="{responsive: true, maintainAspectRatio: false, aspectRatio: 1.5}"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Item from '@/components/lol/Item.vue'
import Rune from '@/components/lol/Rune.vue'
import Spell from '@/components/lol/Spell.vue'
import SkillLevels from '@/components/lol/SkillLevels.vue'
import Line from '@/components/charts/Line.vue'


interface RuneData {
    win_rate: number
    pick_rate: number
    premise: Array<number>
}

@Options({components: {Item, Rune, Spell, SkillLevels, Line}})
export default class ChampionOverview extends Vue {

    @Prop(Object) readonly data!: any

    parseRateHistory (history: Array<Record<string, number>>, label: string, dateFormat: string): any {
        const o: Record<string, any> = {
            labels: [],
            datasets: [
                {
                    label: label,
                    backgroundColor: "#00ffea88",
                    data: []
                }
            ]
        }
        for (const item of history) {
            o.labels.push(this.$moment(item.ts * 1000).format(dateFormat))
            o.datasets[0].data.push(this.$math.round(item.rate * 100, 100))
        }
        return o
    }

    parseRunes (runes: Record<string, Array<RuneData>>, limit = 2): Array<RuneData> {
        const o: Array<RuneData> = []
        for (const runeArr of Object.values(runes)) {
            for (const rune of runeArr) {
                o.push(rune)
            }
        }
        o.sort((a, b) => b.pick_rate - a.pick_rate)
        return o.slice(0, limit)
    }

}
</script>
