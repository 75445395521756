<template>
    <Loading v-if="loading">LOADING</Loading>
    <Error v-else-if="error">{{errorDetail}}</Error>
    <div v-else class="py-4">
        <div class="mx-3 mx-lg-4 mx-xl-5">
            <div class="d-flex">
                <Champion
                    :gid="champId"
                    :r-size="[85, 92, 100]"
                    class="flex-fill mx-1 mx-md-2"
                />
                <div class="flex-fill ms-3 text-left">
                    <Tier :value="data.stat.tier" size="35px" class="ms-1 ms-md-2 ms-lg-3 h5" />
                    <h2 class="d-inline-block ms-3">
                        {{champName}}
                        <span class="text-secondary d-none d-md-inline">
                            <span class="capfirst">{{$asset.lol.roleTitles[role]}}</span>, <span class="capfirst">{{elo}}</span>
                            Elo Builds & Stats
                        </span>
                        <span class="text-secondary d-inline d-md-none">
                            <span class="capfirst">{{$asset.lol.roleTitles[role]}}</span>
                        </span>
                    </h2>
                    <div class="d-flex justify-content-start">
                        <VDropdown
                            v-for="name in ['p', 'q', 'w', 'e', 'r']"
                            :key="'champ-ability-' + name"
                            @hide="(selectedAbilityKey == name) ? selectedAbilityKey = '': null"
                        >
                            <ChampionAbilityIcon
                                :name="name"
                                :gid="champId"
                                :active="name == selectedAbilityKey"
                                :r-size="[35, 37, 42]"
                                @click="(selectedAbilityKey != name) ? selectedAbilityKey = name: selectedAbilityKey = ''"
                                class="my-1 my-md-2 mx-1 mx-md-2 mx-lg-3 cursor-pointer"
                            />
                            <template #popper>
                                <ChampionAbilityDescription
                                    :data="abilities[selectedAbilityKey]"
                                    :gkey="selectedAbilityKey"
                                    :key="selectedAbilityKey"
                                    def-height="200px"
                                    md-height="300px"
                                />
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </div>
            <div class="mt-3 mt-md-4 mx-3 d-flex w-100 flex-wrap">
                <div class="pe-4 text-nowrap mb-3" style="height: 20px">
                    <span class="text-lightgray d-lg-none">Wr: </span>
                    <span class="text-lightgray d-none d-lg-inline">Win Rate: </span>
                    {{$math.round(data.stat.win_rate *100, 100)}}%
                </div>
                <div class="pe-4 text-nowrap mb-3" style="height: 20px">
                    <span class="text-lightgray d-lg-none">Pr: </span>
                    <span class="text-lightgray d-none d-lg-inline">Pick Rate: </span>
                    {{$math.round(data.stat.pick_rate *100, 100)}}%
                </div>
                <div class="pe-4 text-nowrap mb-3" style="height: 20px">
                    <span class="text-lightgray d-lg-none">Br: </span>
                    <span class="text-lightgray d-none d-lg-inline">Ban Rate: </span>
                    {{$math.round(data.stat.ban_rate *100, 100)}}%
                </div>
                <div class="pe-0 text-nowrap mb-3" style="height: 20px">
                    <span class="text-lightgray">Last Updated: </span>
                    {{$moment(data.stat.last_updated).from()}}
                </div>
            </div>
            <div class="mt-0 mt-md-2 py-2 d-flex w-100">
                <Icon name="filter" size="40px" class="mx-2 mx-md-3" />
                <Select
                    :options="availableRoles(data.stat.role_rates)"
                    v-model="role"
                    class="mx-1 mx-md-2"
                    width="160px"
                    z-index="1"
                ></Select>
                <Select
                    :options="$asset.lol.longElos"
                    v-model="elo"
                    class="mx-1 mx-md-2"
                    width="120px"
                    z-index="2"
                ></Select>
            </div>
        </div>
        <VenatusAd class="mx-3 mt-2 mx-lg-4 mx-xl-5" :height="100" />
        <div class="mt-2 mt-md-4 mx-3 mx-lg-4 mx-xl-5 detail-bg">
            <div class="border-bottom border-secondary border-2 px-2 px-md-4 px-xxl-5 overflow-auto d-flex">
                <div
                    :class="['tab-button px-4 px-lg-5 py-3', ($store.state.winWidth < 768) ? 'small': '', (selectedPanel == 'overview') ? 'active': '']"
                    @click="selectedPanel = 'overview'"
                >Overview</div>
                <div
                    :class="['tab-button px-4 px-lg-5 py-3', ($store.state.winWidth < 768) ? 'small': '', (selectedPanel == 'builds') ? 'active': '']"
                    @click="selectedPanel = 'builds'"
                >Builds</div>
                <div
                    :class="['tab-button px-4 px-lg-5 py-3', ($store.state.winWidth < 768) ? 'small': '', (selectedPanel == 'runes') ? 'active': '']"
                    @click="selectedPanel = 'runes'"
                >Runes</div>
                <div
                    :class="['tab-button px-4 px-lg-5 py-3 text-nowrap', ($store.state.winWidth < 768) ? 'small': '', (selectedPanel == 'skills') ? 'active': '']"
                    @click="selectedPanel = 'skills'"
                >Spells & Abilities</div>
                <div
                    :class="['tab-button px-4 px-lg-5 py-3', ($store.state.winWidth < 768) ? 'small': '', (selectedPanel == 'trends') ? 'active': '']"
                    @click="selectedPanel = 'trends'"
                >Trends</div>
            </div>
            <transition name="tab-fade" mode="out-in">
                <ChampionOverview v-if="selectedPanel == 'overview'" :data="data" />
                <ChampionBuilds v-else-if="selectedPanel == 'builds'" :data="data" />
                <ChampionRunes v-else-if="selectedPanel == 'runes'" :data="data" />
                <ChampionSkills v-else-if="selectedPanel == 'skills'" :data="data" />
                <ChampionTrends v-else-if="selectedPanel == 'trends'" :data="data" />
            </transition>
            
        </div>
        <VenatusAd class="mt-4 mb-2 mx-3 mx-lg-4 mx-xl-5" :height="300" />
    </div>
</template>

<style lang="scss" scoped>
@import '@/scss/transitions.scss';

@include fade('tab-fade', .06s);

.overflow-scroll {
    overflow-x: scroll;
}

.detail-bg {
    background: linear-gradient(to right, rgba(31, 226, 233, 0.115) 0%, rgba(31, 226, 233, 0.19) 100%);
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'
import ChampionAbilityIcon from '@/components/lol/ChampionAbilityIcon.vue'
import ChampionAbilityDescription from '@/components/lol/ChampionAbilityDescription.vue'
import ChampionOverview from '@/components/lol/ChampionOverview.vue'
import ChampionBuilds from '@/components/lol/ChampionBuilds.vue'
import ChampionRunes from '@/components/lol/ChampionRunes.vue'
import ChampionSkills from '@/components/lol/ChampionSkills.vue'
import ChampionTrends from '@/components/lol/ChampionTrends.vue'

@Options({
    components: {
        Champion,
        ChampionAbilityIcon,
        ChampionAbilityDescription,
        ChampionOverview,
        ChampionBuilds,
        ChampionRunes,
        ChampionSkills,
        ChampionTrends
    }
})
export default class LolChampions extends Vue {

    elo = ""
    role = ""
    loading = true
    error = false
    errorDetail = ""
    champKey = ""
    champName = ""
    champId = -1
    data: any = {}
    abilities: any = {}
    
    selectedAbilityKey = ""
    selectedPanel = "overview"

    @Watch('role')
    roleChanged(after: string, before: string): void {
        if (before)
            this.$router.push(`/lol/champions/${this.elo}/${this.champKey}/${after}`)
    }

    @Watch('elo')
    eloChanged(after: string, before: string): void {
        if (before)
            this.$router.push(`/lol/champions/${after}/${this.champKey}/${this.role}`)
    }

    async created (): Promise<void> {
        this.elo = this.$route.params.elo.toString()
        this.champKey = this.$route.params.key.toString()
        this.role = this.$route.params.role.toString()
        if (this.$asset.lol.champkeys[this.champKey] == undefined) {
            this.loading = false
            this.error = true
            this.errorDetail = "Champion not found"
        }
        this.champId = this.$asset.lol.champkeys[this.champKey].id
        this.champName = this.$asset.lol.champkeys[this.champId].name
        try {
            const stat = this.$cdn.get('lol', ['analytics', 'champion-stats', this.elo, this.champId, this.role], 'json')
            const build = this.$cdn.get('lol', ['analytics', 'champion-builds', this.elo, this.champId, this.role], 'json')
            this.data.stat = await stat
            this.data.style = await build
        } catch (err: any) {
            this.loading = false
            this.error = true
            this.errorDetail = err.response.data.detail
            return
        }
        try {
            this.abilities = await this.$cdn.get('lol', ['champions', this.champId + "_abilities"], "json")
        } catch (err) {
            this.abilities = {e: [], p: [], q: [], r: [], w: []}
        }
        this.$meta.use({
            title: `${this.champName} ${this.capfirst(this.role)} ${this.capfirst(this.elo)} Elo Builds, Runes, Stats | StatHub.gg LoL`,
            og: {
                image: this.$cdn.url('lol', ['champions', this.champId], 'png'),
                description: `${this.champName} ${this.capfirst(this.role)} ${this.capfirst(this.elo)} Elo |
                    Most up to date builds, runes, skill orders and statistics |
                    Win Rate: ${this.$math.round(this.data.stat.win_rate *100, 100)}% |
                    Pick Rate: ${this.$math.round(this.data.stat.pick_rate *100, 100)}% |
                    Ban Rate: ${this.$math.round(this.data.stat.ban_rate *100, 100)}% |
                    `
            }
        })
        this.loading = false
    }

    capfirst(str: string): string {
        return str.charAt(0).toUpperCase() + str.substring(1)
    }

    availableRoles (roles: Record<string, number>): Array<any> {
        const record = this.$asset.asRecord(this.$asset.lol.longRoles)
        const o = []
        for (const [k, v] of Object.entries(roles)) {
            o.push({
                title: record[k] + " " + this.$math.round(v * 100, 100) + "%",
                value: k
            })
        }
        return o
    }

}
</script>
