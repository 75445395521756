
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SkillLevels from '@/components/lol/SkillLevels.vue'
import Spell from '@/components/lol/Spell.vue'

@Options({components: {SkillLevels, Spell}})
export default class ChampionRunes extends Vue {

    @Prop(Object) readonly data!: any

}
