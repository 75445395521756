<template>
    <div class="d-flex flex-wrap">
        <div v-for="(slot, ind) in slots" :key="ind">
            <div style="width: 20px" class="text-nowrap extra-small slight-dark-bg">
                <span class="text-lightgray" v-if="slot != 4">{{ind + 1}}</span>
                <span class="text-warning fw-bold" v-else>{{ind + 1}}</span>
            </div>
            <div class="slight-dark-bg-2 text-info fw-bold small" v-if="slots.lastIndexOf(slot) == ind">
                {{toKeys[slot - 1]}}
            </div>
            <div class="slight-dark-bg-2 small" v-else>
                {{toKeys[slot - 1]}}
            </div>
        </div>
    </div>    
</template>

<style lang="scss" scoped>

.slight-dark-bg {
    background-color: rgba(0, 0, 0, 0.5);
}

.slight-dark-bg-2 {
    background-color: rgba(0, 0, 0, 0.3);
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({})
export default class SkillLevels extends Vue {

    toKeys: Array<string> = ['Q', 'W', 'E', 'R']

    @Prop(Array) readonly slots!: Array<number>

}
</script>
