
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({})
export default class SkillLevels extends Vue {

    toKeys: Array<string> = ['Q', 'W', 'E', 'R']

    @Prop(Array) readonly slots!: Array<number>

}
