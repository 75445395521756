
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({})
export default class ChampionTrends extends Vue {

    @Prop(Object) readonly data!: any

    parseRateHistory (history: Array<Record<string, number>>, label: string, dateFormat: string): any {
        const o: Record<string, any> = {
            labels: [],
            datasets: [
                {
                    label: label,
                    backgroundColor: "#00ffea88",
                    data: []
                }
            ]
        }
        for (const item of history) {
            o.labels.push(this.$moment(item.ts * 1000).format(dateFormat))
            o.datasets[0].data.push(this.$math.round(item.rate * 100, 100))
        }
        return o
    }

}
